import React from "react";
import { ReactFC } from '../scripts/types'

const LinkedinIcon: ReactFC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1999 8.80005C16.4729 8.80005 17.6938 9.30576 18.594 10.2059C19.4942 11.1061 19.9999 12.327 19.9999 13.6V19.2H16.7999V13.6C16.7999 13.1757 16.6313 12.7687 16.3313 12.4687C16.0312 12.1686 15.6242 12 15.1999 12C14.7756 12 14.3686 12.1686 14.0685 12.4687C13.7685 12.7687 13.5999 13.1757 13.5999 13.6V19.2H10.3999V13.6C10.3999 12.327 10.9056 11.1061 11.8058 10.2059C12.706 9.30576 13.9269 8.80005 15.1999 8.80005Z"/>
            <path d="M7.2 9.6001H4V19.2001H7.2V9.6001Z"/>
            <path d="M5.6 7.2C6.48366 7.2 7.2 6.48366 7.2 5.6C7.2 4.71634 6.48366 4 5.6 4C4.71634 4 4 4.71634 4 5.6C4 6.48366 4.71634 7.2 5.6 7.2Z"/>
        </svg>

    )
}

export default LinkedinIcon