import React from "react";
import { ReactFC } from '../scripts/types'

const EmailIcon: ReactFC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.77778 6H18.2222C18.948 6 19.5721 6.43486 19.8484 7.05824L12 12.2905L4.15163 7.05826C4.42786 6.43488 5.05203 6 5.77778 6ZM4 8.75995V16.6667C4 17.6485 4.79594 18.4444 5.77778 18.4444H18.2222C19.2041 18.4444 20 17.6485 20 16.6667V8.75992L12.416 13.8159C12.1641 13.9839 11.8359 13.9839 11.584 13.8159L4 8.75995Z" fill="white"/>
        </svg>
    )
}

export default EmailIcon