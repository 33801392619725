import React from "react";
import { ReactFC } from '../scripts/types'

const TwitterIcon: ReactFC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M20 6.08848C19.2 6.48048 18.416 6.63968 17.6 6.88048C16.7032 5.86848 15.3736 5.81248 14.096 6.29088C12.8184 6.76928 11.9816 7.93888 12 9.28048V10.0805C9.404 10.1469 7.092 8.96448 5.6 6.88048C5.6 6.88048 2.2544 12.8269 8.8 15.6805C7.3024 16.6781 5.8088 17.3509 4 17.2805C6.6464 18.7229 9.5304 19.2189 12.0272 18.4941C14.8912 17.6621 17.2448 15.5157 18.148 12.3005C18.4174 11.3226 18.5512 10.3124 18.5456 9.29808C18.544 9.09888 19.7536 7.08048 20 6.08768V6.08848Z" />
        </svg>
    )
}

export default TwitterIcon