import React, { ReactNode } from "react"
import { ReactFC } from '../../scripts/types'
import './about-item.css'
import '../../styles/variables.css'

const AboutItem: ReactFC<{
    image: ReactNode,
    header: string,
    description: string
}> = ({ className = '', image, header, description }) => {
    return (
        <div className={`${className} aboutItemContainer`}>
            <div>{image}</div>
            <div className='contentContainer'>
                <span className='title font-size-24'>{header}</span>
                <span className='aboutDescription font-size-16'>{description}</span>
            </div>
        </div>
    )
}

export default AboutItem