import React from "react"
import { ReactFC } from '../scripts/types'

const Vector: ReactFC<{}> = ({ className = '' }) => {
    return (
        <svg className={className} width="432" height="696" viewBox="0 0 432 696" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="432" height="696" rx="16" fill="black"/>
        </svg>
    )
}

export default Vector