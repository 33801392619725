import React, { useState } from "react"
import { ReactFC } from '../../scripts/types'
import './navbar.css'
import '../../styles/variables.css'
import Button from "../../blocks/gradientButton"

type NavItems = 'HOME' | 'APP' | 'FEATURE' | 'TOKENOMICS' | 'ROADMAP' | 'MORE'

const Navbar: ReactFC<{
    onChangeNavItem?: (item: NavItems) => void
}> = ({ onChangeNavItem, className = '' }) => {
    const [selectedNavItem, setSelectedNavItem] = useState<NavItems>('HOME')

    const onSelectNavItem = (item: NavItems) => {
        setSelectedNavItem(item)
        if (onChangeNavItem) onChangeNavItem(item)
    }

    return (
        <header className={`${className} headerContainer`}>
            <a href='/' className='headerContainer__typography font-size-24'>Wojak.</a>

            <div className='navItemContainer'>
                <a href='/#home' onClick={() => onSelectNavItem('HOME')} className={`navItemContainer__navItem${selectedNavItem === 'HOME' ? '--enable' : ''}`}>Home</a>
                <a href='/#app' onClick={() => onSelectNavItem('APP')} className={`navItemContainer__navItem${selectedNavItem === 'APP' ? '--enable' : ''}`}>App</a>
                <a href='/#features' onClick={() => onSelectNavItem('FEATURE')} className={`navItemContainer__navItem${selectedNavItem === 'FEATURE' ? '--enable' : ''}`}>Features</a>
                <a href='/#tokenomics' onClick={() => onSelectNavItem('TOKENOMICS')} className={`navItemContainer__navItem${selectedNavItem === 'TOKENOMICS' ? '--enable' : ''}`}>Tokenomics</a>
                <a href='/#roadmap' onClick={() => onSelectNavItem('ROADMAP')} className={`navItemContainer__navItem${selectedNavItem === 'ROADMAP' ? '--enable' : ''}`}>Roadmap</a>
                <a href='/#more' onClick={() => onSelectNavItem('MORE')} className={`navItemContainer__navItem${selectedNavItem === 'MORE' ? '--enable' : ''}`}>More</a>
            </div>

            <Button type='SOLID' label='Whitepaper'/>
        </header>
    )
}

export default Navbar