import React from "react"
import { ReactFC } from '../scripts/types'

const TwitchIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 2H5.03999C4.70999 2 4.39001 2.17001 4.21001 2.45001L2.67 4.75C2.56 4.91 2.5 5.10999 2.5 5.29999V18C2.5 18.55 2.95 19 3.5 19H5.5C6.05 19 6.5 19.45 6.5 20V21C6.5 21.55 6.95 22 7.5 22H8.96001C9.29001 22 9.60999 21.83 9.78999 21.55L11.2 19.44C11.39 19.16 11.7 18.99 12.03 18.99H16.08C16.35 18.99 16.6 18.88 16.79 18.7L21.2 14.29C21.39 14.1 21.49 13.85 21.49 13.58V3C21.5 2.45 21.05 2 20.5 2ZM10.79 12.86C10.79 13.18 10.53 13.43 10.22 13.43H9.08C8.76 13.43 8.50999 13.17 8.50999 12.86V7.14999C8.50999 6.82999 8.77 6.57999 9.08 6.57999H10.22C10.54 6.57999 10.79 6.83999 10.79 7.14999V12.86ZM16.5 12.86C16.5 13.18 16.24 13.43 15.93 13.43H14.79C14.47 13.43 14.22 13.17 14.22 12.86V7.14999C14.22 6.82999 14.48 6.57999 14.79 6.57999H15.93C16.25 6.57999 16.5 6.83999 16.5 7.14999V12.86Z" fill="#F6F2FF"/>
        </svg>
    )
}

export default TwitchIcon