import React from "react";
import { ReactFC } from '../scripts/types'

const ArrowPrevIcon: ReactFC = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M44 24.0004C44 12.9546 35.0451 4.00037 24 4.00037C12.9549 4.00037 4 12.9546 4 24.0004C4 35.0448 12.9556 44.0004 24 44.0004C35.0444 44.0004 44 35.0448 44 24.0004ZM41 24.0004C41 33.3879 33.3876 41.0004 24 41.0004C14.6124 41.0004 7 33.3879 7 24.0004C7 14.6115 14.6117 7.00037 24 7.00037C33.3883 7.00037 41 14.6115 41 24.0004ZM28.0886 17.953C28.5255 17.3667 28.4789 16.5333 27.9475 15.9996C27.363 15.4125 26.4133 15.4105 25.8262 15.995L18.8542 22.937L18.7082 23.1055C18.2703 23.6936 18.3189 24.5299 18.8542 25.0629L25.8262 32.0049L25.9948 32.1498C26.5829 32.5842 27.4162 32.534 27.9475 32.0003L28.0924 31.8318C28.5269 31.2436 28.4767 30.4104 27.943 29.879L22.04 23.998L27.943 18.1209L28.0886 17.953Z" fill="white"/>
        </svg>

    )
}

export default ArrowPrevIcon