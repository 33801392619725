import React from "react";
import { ReactFC } from '../scripts/types'

const MinusIcon: ReactFC = ({ className = '' }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16H24" stroke="#868E96" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default MinusIcon