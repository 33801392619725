import React from "react"
import { ReactFC } from '../../scripts/types'
import './tokenomics-item.css'
import '../../styles/variables.css'

const TokenomicsItem: ReactFC<{
    title: string,
    description: string,
    descriptionSize?: 'LARGE' | 'SMALL'
}> = ({
    title, description, descriptionSize = 'SMALL', className = ''
}) => {
    return  (
        <div className={`${className} tokenItemContainer`}>
            <div className='tokenItemContainer__titleContainer'>
                <div className='tokenItemContainer__titleContainer__line'></div>
                <span className='tokenItemContainer__titleContainer__title'>{title}</span>
            </div>
            <span className={`tokenItemContainer__description ${ descriptionSize === 'SMALL' ? 'font-size-16' : 'font-size-24'}`}>
                {description}
            </span>
        </div>
    )
}

export default TokenomicsItem