import React, { useState } from "react";
import { ReactFC } from '../../scripts/types'
import './expandable-box.css'
import MinusIcon from "../../icons/minus";
import PlusIcon from "../../icons/plus";

const ExpandableBox: ReactFC<{
    title: string,
    description: string,
    hasBottomLine?: boolean
}> = ({ title, description, className = '', hasBottomLine = false }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div className={`${className} expandableBoxContainer ${hasBottomLine ? 'bottomLine' : ''}`}>
            <div className='expandableBoxContainer__titleContainer' onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <MinusIcon /> : <PlusIcon />}
                <span className='expandableBoxContainer__titleContainer__title'>{title}</span>
            </div>
            <div className={`expandableBoxContainer__contentContainer expandableBoxContainer__contentContainer${isExpanded ? '--open' : ''}`}>
                <span className={`expandableBoxContainer__contentContainer__content`}>
                    {description}
                </span>
            </div>
        </div>
    )
}

export default ExpandableBox