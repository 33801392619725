import React from "react";
import { ReactFC } from '../scripts/types'

const CommunityIcon: ReactFC<{}> = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="80" rx="16" fill="url(#paint0_linear_1_247)"/>
            <path d="M51.9999 30.32C51.8799 30.3 51.7399 30.3 51.6199 30.32C48.8599 30.22 46.6599 27.96 46.6599 25.16C46.6599 22.3 48.9599 20 51.8199 20C54.6799 20 56.9799 22.32 56.9799 25.16C56.9599 27.96 54.7599 30.22 51.9999 30.32Z" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M49.9399 44.8801C52.6799 45.3401 55.6999 44.8601 57.8199 43.4401C60.6399 41.5601 60.6399 38.4801 57.8199 36.6001C55.6799 35.1801 52.6199 34.7001 49.8799 35.1801" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M27.94 30.32C28.06 30.3 28.2 30.3 28.32 30.32C31.08 30.22 33.28 27.96 33.28 25.16C33.28 22.3 30.98 20 28.12 20C25.26 20 22.96 22.32 22.96 25.16C22.98 27.96 25.18 30.22 27.94 30.32Z" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M29.9999 44.8801C27.2599 45.3401 24.2399 44.8601 22.1199 43.4401C19.2999 41.5601 19.2999 38.4801 22.1199 36.6001C24.2599 35.1801 27.3199 34.7001 30.0599 35.1801" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M39.9999 45.2599C39.8799 45.2399 39.7399 45.2399 39.6199 45.2599C36.8599 45.1599 34.6599 42.8999 34.6599 40.0999C34.6599 37.2399 36.9599 34.9399 39.8199 34.9399C42.6799 34.9399 44.9799 37.2599 44.9799 40.0999C44.9599 42.8999 42.7599 45.1799 39.9999 45.2599Z" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M34.1799 51.56C31.3599 53.44 31.3599 56.52 34.1799 58.4C37.3799 60.54 42.6199 60.54 45.8199 58.4C48.6399 56.52 48.6399 53.44 45.8199 51.56C42.6399 49.44 37.3799 49.44 34.1799 51.56Z" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
            <linearGradient id="paint0_linear_1_247" x1="-6.32653" y1="-15.2727" x2="95.5719" y2="-6.05494" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F6F2FF"/>
            <stop offset="1" stop-color="#D4BBFF"/>
            </linearGradient>
            </defs>
        </svg>
    )
}

export default CommunityIcon