import React from "react";
import { ReactFC } from '../scripts/types'

const BoxIcon: ReactFC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9999 36.6666H24.9999C33.3333 36.6666 36.6666 33.3333 36.6666 24.9999V14.9999C36.6666 6.66659 33.3333 3.33325 24.9999 3.33325H14.9999C6.66659 3.33325 3.33325 6.66659 3.33325 14.9999V24.9999C3.33325 33.3333 6.66659 36.6666 14.9999 36.6666Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M23.9185 18.1835C23.7518 19.3669 23.0518 20.3669 22.0852 20.9669V24.2669C22.0852 25.4169 21.1518 26.3502 20.0018 26.3502C18.8518 26.3502 17.9185 25.4169 17.9185 24.2669V20.9669C16.9518 20.3669 16.2518 19.3669 16.0852 18.1835C16.0518 18.0002 16.0352 17.8002 16.0352 17.6002C16.0352 15.0669 18.4352 13.1002 21.0685 13.8002C22.4019 14.1502 23.4851 15.2335 23.8351 16.5669C23.9851 17.1169 24.0018 17.6669 23.9185 18.1835Z" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M36.6692 18.1836H23.9192" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M16.0832 18.1833H3.33325" stroke-width="2" stroke-miterlimit="10"/>
        </svg>
    )
}

export default BoxIcon