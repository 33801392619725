import React from "react"
import { ReactFC } from '../scripts/types'

const SmallVector: ReactFC = ({ className = '' }) => {
    return (
        <svg className={`${className}`} width="430" height="560" viewBox="0 0 430 560" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="430" height="560" rx="16" fill="black"/>
        </svg>
    )
}

export default SmallVector