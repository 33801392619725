import React, { ReactNode, useState } from "react"
import { ReactFC } from '../../scripts/types'
import Navbar from "../../components/navbar"
import '../../styles/variables.css'
import './landing.css'
import ArrowIcon from '../../icons/arrow'
import Vector from '../../icons/vector'
import FacebookIcon from '../../icons/facebook'
import InstagramIcon from "../../icons/instragram"
import TwitchIcon from "../../icons/twitch"
import TwitterIcon from "../../icons/twitter"
import Button from "../../blocks/gradientButton"
import PockerVector from "../../icons/pocker"
import HappyVector from "../../icons/happy"
import OverJoyedVector from "../../icons/overjoyed"
import AboutItem from "../../components/aboutItem"
import BoxIcon from "../../icons/box"
import CommunityIcon from "../../icons/community"
import WalletIcon from "../../icons/wallet"
import FeatureItem from '../../components/featureItem'
import SmallVector from "../../icons/smallVector"
import CircleVector from "../../icons/circleVector"
import TokenomicsItem from "../../components/tokenomicsItem"
import AppStoreIcon from "../../icons/appStore"
import GooglePlayIcon from "../../icons/googlePlay"
import phoneSvg from '../../Phone.svg'
import PhoneIcon from "../../icons/phoneIcon"
import EmailIcon from "../../icons/email"
import TwitterSmallIcon from '../../icons/twitterIcon'
import LinkedinIcon from "../../icons/linkedin"
import FacebookSmallIcon from "../../icons/facebookIcon"
import InstagramSmallIcon from "../../icons/instagram"
import TextField from '@mui/material/TextField'
import ExpandableBox from "../../components/expandableBox"
import BoxSmallIcon from "../../icons/boxIcon"
import Carousel from 'react-elastic-carousel';
import ArrowPrevIcon from "../../icons/arrowPrev"
import ArrowNextIcon from "../../icons/arrowNext"
import TrustWalletIcon from "../../icons/trustWallet"
import CoinMarketCapIcon from "../../icons/coinMarketCap"
import CoinGeckoIcon from "../../icons/coinGecko"
import NomicsIcon from "../../icons/nomics"
import RoadmapVector from "../../icons/roadmapVector"
import image51 from '../../image51.svg'
import image52 from '../../image52.svg'
import image53 from '../../image53.svg'
import image54 from '../../image54.svg'

const Landing: ReactFC = () => {
    const [items, setItems] = useState<ReactNode[]>([
        <img src={image51} />,
        <img src={image52} />,
        <img src={image53} />,
        <img src={image54} />,
        <img src={image51} />,
        <img src={image52} />,
        <img src={image53} />,
        <img src={image54} />
    ])

    return (
        <div className='landingPage'>
            <div className='box'></div>
            <Navbar className='mainContainer header'/>
            <section className='mainContainer introSection'>
                <div className=''>
                    <div className='introduceTitleContainer'>
                        <div className='titleContainer'>
                            <span className='font-size-64 title'>Wojak Finance</span>
                            <span className='font-size-64 title gradientText marginTop-16'>#WojakToken</span>
                            <span className='description font-size-18'>The $WOJ token is a charity token, we help educate you, we help you learn to earn, #WojakToken is based on the BEP20 standard.</span>
                            <div className='actionContainer'>
                                <Button className='actionButton marginRight' type='SOLID' label='Buy on PancakeSwap'/>
                                <Button className='actionButton marginLeft' type='OUTLINE' label='Buy on Hotbid'/>
                            </div>
                        </div>
                        <ArrowIcon className='arrow'/>
                    </div>

                    <div className='vectorContainer'>
                        <PockerVector />
                        <HappyVector />
                        <OverJoyedVector />
                        <OverJoyedVector />
                        <OverJoyedVector />
                    </div>
                </div>
                <div className='rightContainer'>
                    <Vector className='vector'/>
                    <div className='socialContainer'>
                        <a className='socialItem' href="/#facebook">
                            <FacebookIcon />
                        </a>
                        <a className='socialItem' href="">
                            <InstagramIcon />
                        </a>
                        <a className='socialItem' href="">
                            <TwitchIcon />
                        </a>
                        <a className='socialItem' href="">
                            <TwitterIcon />
                        </a>
                    </div>
                </div>
            </section>
            <section className='aboutSection'>
                <div className='img'></div>
                <div className='mainContainer aboutContainer'>
                    <div className='titleContainer'>
                        <span className='font-size-64 title'>About Wojak</span>
                        <span className='description font-size-18'>The $WOJ token is a charity token, we help educate you, we help you learn to earn, #WojakToken is based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits from within our wojak finance ecosystem, as it may be used as the governance token in the future.</span>
                        <div className='actionContainer actionButton'>
                            <Button type='SOLID' label='Learn More'/>
                        </div>
                    </div>
                    <div className='aboutItemsContainer'>
                        <AboutItem
                            image={<CommunityIcon />}
                            header={'Your Community'}
                            description={'We are proud of our shared vision, which was created by and for the people. to help our crypto commuunity grow strong together.'}
                        />
                        <AboutItem
                            className='marginTop'
                            image={<BoxIcon />}
                            header={'Clarity of Purpose and Focus'}
                            description={'We openly exchange information with you. We’re well-organized, well-coordinated, and we’re all pulling in the same direction.'}
                        />
                        <AboutItem
                            className='marginTop'
                            image={<WalletIcon />}
                            header={'Sustainability'}
                            description={'The future is upon us, and we are prepared. We weigh the consequences of any action we make, and we are dedicated to a long-term strategy. #WojakWins #WojakToken'}
                        />
                    </div>
                </div>
            </section>
            <section className='featureContainer'>
                <div className='mainContainer'>
                    <div className='featureTitleContainer'>
                        <span className='font-size-64 title featureTitle'>Key Features</span>
                        <div className='line'></div>
                    </div>

                    <div className='featureDescription'>
                        <span className='font-size-18 featureDescription__description'>Wojak is one of the most recognizable memic characters in the crypto world, named after the Polish word for soldier.</span>
                    </div>
                </div>
                <div className='mainContainer featureDetailContainer'>
                    <div className='featureListContainer'>
                        <FeatureItem
                            header='Hyper-Deflationary Token'
                            description='As it is a deflationary token, the value will gradually increase based on the number of transactions made. Therefore, the more Wojak tokens users hold and donate, the more reward they can expect.'
                            image={<BoxSmallIcon/>}
                        />
                        <FeatureItem
                            className='featureListContainer__marginTop'
                            header='Hyper-Deflationary Token'
                            description='As it is a deflationary token, the value will gradually increase based on the number of transactions made. Therefore, the more Wojak tokens users hold and donate, the more reward they can expect.'
                            image={<BoxSmallIcon/>}
                        />
                        <FeatureItem
                            className='featureListContainer__marginTop'
                            header='Hyper-Deflationary Token'
                            description='As it is a deflationary token, the value will gradually increase based on the number of transactions made. Therefore, the more Wojak tokens users hold and donate, the more reward they can expect.'
                            image={<BoxSmallIcon/>}
                        />
                    </div>
                    <SmallVector className='featureVector'/>
                </div>
            </section>
            <section>
                <div className='mainContainer tokenomicsContainer'>
                    <div className='featureTitleContainer'>
                        <span className='font-size-64 title featureTitle'>Tokenomics</span>
                        <div className='line'></div>
                    </div>

                    <div className='featureDescription'>
                        <span className='font-size-18 featureDescription__description'>Note: Use 9% slippage when swapping on Pancake Swap. If you are having issues, please check our How To Buy section</span>
                    </div>
                </div>
                <div className='mainContainer tokenomicsDetailContainer'>
                    <div className='tokenomicsItemsContainer'>
                        <TokenomicsItem title='Contract Address' description='0x55f96c7005d7c684a65ee653b07b5fe1507c56ab' descriptionSize='LARGE' />
                        <div className='tokenomicsItemsContainer__tokenomicsItemsRow'>
                            <div className='tokenomicsItemsContainer__row'>
                                <TokenomicsItem title='Token Name' description='WOJ' className='tokenomicsItemsContainer__row__item'/>
                                <TokenomicsItem title='Emission Rate' description='No new tokens will be created' className='tokenomicsItemsContainer__row__item'/>
                            </div>
                            <div className='tokenomicsItemsContainer__row'>
                                <TokenomicsItem title='Token Network' description='Binance Smart Chain' className='tokenomicsItemsContainer__row__item'/>
                                <TokenomicsItem title='Finalization time' description='Minutes after hard cap has been reached' className='tokenomicsItemsContainer__row__item'/>
                            </div>
                        </div>
                    </div>
                    <CircleVector className='tokenomicsDetailContainer__circleVector' />
                </div>
            </section>
            <section className='mainContainer roadMapContainer'>
                <div className='featureTitleContainer'>
                    <span className='font-size-64 title featureTitle'>RoadMap</span>
                    <div className='line'></div>
                </div>
                <div className='featureDescription'>
                    <span className='font-size-18 featureDescription__description'>Soon, we have another exciting news for our investors that we will launch our own swap like pancake swap called WojakSwap.</span>
                </div>
            </section>
            <div className='roadmapVectorContainer'>
                <RoadmapVector />
            </div>
            <section className='appDownloadContainer'>
                <div className='backgroundContainer'>
                    <div className='purple'></div>
                    <div className='transparent'></div>
                </div>
                <div className='appDownloadContainerMain mainContainer'>
                    <img className='phoneVector' src={phoneSvg} alt="" />
                    <div className='appDownloadDescriptionContianer'>
                        <span className='appDownloadDescriptionContianer__header'>New WOJAK app</span>
                        <div className=''>
                            <ul>
                                <li><div/>Total rewards per wallet #WojakRewards</li>
                                <li><div/>The Amount of $WOJ tokens burned in realtime</li>
                                <li><div/>LP and MC ratio in realtime</li>
                                <li><div/>CoinGecko Stats</li>
                                <li><div/>Buy Now Links</li>
                                <li><div/>Wallet QR code</li>
                                <li><div/>Complete Social List</li>
                                <li><div/>News and updates</li>
                            </ul>
                        </div>
                        <span className='font-size-16 title appDownloadDescriptionContianer__actionTitle'>Get the Woj Mobile App Now!</span>
                        <div className='appDownloadDescriptionContianer__actionButtons'>
                            <button className='appDownloadDescriptionContianer__actionButtons appDownloadDescriptionContianer__actionButtons__outline googleplayBtn'>
                                <div className='btnDetail'>
                                    <GooglePlayIcon />
                                    <div className='btnTextContainer'>
                                        <span className='btnTextContainer__description font-size-12'>GET IT ON</span>
                                        <span className='btnTextContainer__title font-size-16'>Google Play</span>
                                    </div>
                                </div>
                            </button>
                            <button className='appDownloadDescriptionContianer__actionButtons appDownloadDescriptionContianer__actionButtons__outline applestoreBtn'>
                                <div className='btnDetail'>
                                    <AppStoreIcon />
                                    <div className='btnTextContainer'>
                                        <span className='btnTextContainer__description font-size-12'>Download on the</span>
                                        <span className='btnTextContainer__title font-size-16'>App Store</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='mainContainer roadMapContainer'>
                <div className='featureTitleContainer'>
                    <span className='font-size-64 title featureTitle'>FAQ</span>
                    <div className='line'></div>
                </div>
                <div className='featureDescription'>
                    <span className='font-size-18 featureDescription__description'>Below we’ve provided a bit of WOJ Token, cryptocurrencies, and few others. If you have any other questions, please get in touch using the contact form below.</span>
                </div>
            </section>
            <div className='expandContainer'>
                <ExpandableBox
                    title='What is $WOJ?'
                    description='The $WOJ token is a charity token, based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits within our wojak finance ecosystem, as it may be used as the governance token in the future.'
                    hasBottomLine={true}
                />
                <ExpandableBox
                    title='How can I buy $WOJ?'
                    description='The $WOJ token is a charity token, based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits within our wojak finance ecosystem, as it may be used as the governance token in the future.'
                    hasBottomLine={true}
                />
                <ExpandableBox
                    title='What does Wojak mean?'
                    description='The $WOJ token is a charity token, based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits within our wojak finance ecosystem, as it may be used as the governance token in the future.'
                    hasBottomLine={true}
                />
                <ExpandableBox
                    title='Is the source code of the $WOJ smart contract audited?'
                    description='The $WOJ token is a charity token, based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits within our wojak finance ecosystem, as it may be used as the governance token in the future.'
                    hasBottomLine={true}
                />
                <ExpandableBox
                    title='How can I submit my application?'
                    description='The $WOJ token is a charity token, based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits within our wojak finance ecosystem, as it may be used as the governance token in the future.'
                    hasBottomLine={true}
                />
                <ExpandableBox
                    title='What is the official contract address?'
                    description='The $WOJ token is a charity token, based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits within our wojak finance ecosystem, as it may be used as the governance token in the future.'
                    hasBottomLine={true}
                />
                <ExpandableBox
                    title='Why does my $WOJ token not show up in the trust wallet after I purchased them?'
                    description='The $WOJ token is a charity token, based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits within our wojak finance ecosystem, as it may be used as the governance token in the future.'
                    hasBottomLine={true}
                />
                <ExpandableBox
                    title='What is your donation process?'
                    description='The $WOJ token is a charity token, based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits within our wojak finance ecosystem, as it may be used as the governance token in the future.'
                    hasBottomLine={true}
                />
                <ExpandableBox
                    title='Are there any rules or conditions to be followed?'
                    description='The $WOJ token is a charity token, based on the BEP20 standard. Using $WOJ tokens the holders can enjoy multiple benefits within our wojak finance ecosystem, as it may be used as the governance token in the future.'
                    hasBottomLine={false}
                />
            </div>
            <div className='carouselContainer'>
                <Carousel
                    isRTL={false}
                    itemsToShow={4}
                    renderArrow={({type, onClick}) => <div onClick={onClick}>{type === 'PREV' ? <ArrowPrevIcon /> : <ArrowNextIcon />}</div>}
                    renderPagination={({ pages, activePage, onClick }) => <div className='carouselContainer__pagination'>
                        {pages.map(item => item === activePage ?
                            <div className='carouselContainer__pagination--active' /> :
                            <div className='carouselContainer__pagination--deActive' />
                        )}
                    </div>}
                >
                    {items.map((item, index) => <div key={index}>{item}</div>)}
                </Carousel>
            </div>
            <section className='footerContainer'>
                <div className='mainContainer'>
                    <div className='footerContainer__left'>
                        <span className='title font-size-64'>Contact Woj</span>
                        <span className='font-size-16 light-font-color footerContainer__left__joinText'>
                            Interested in joining our team?
                        </span>
                        <Button className='footerContainer__left__applyBtn' type='SOLID' label='Apply Now' />
                        <div className='footerContainer__left__phoneContainer'>
                            <PhoneIcon />
                            <span className='phone font-size-16'>(123) 456-3467</span>
                        </div>
                        <div className='footerContainer__left__emailContainer'>
                            <EmailIcon />
                            <span className='mail font-size-16'>woj@finance.com</span>
                        </div>
                        <span className='followText title font-size-24'>Follow us</span>
                        <div className='socialMediaListContainer'>
                            <div className='circle'>
                                <TwitterSmallIcon />
                            </div>
                            <div className='circle circle__marginLeft'>
                                <LinkedinIcon />
                            </div>
                            <div className='circle circle__marginLeft'>
                                <FacebookSmallIcon />
                            </div>
                            <div className='circle circle__marginLeft'>
                                <InstagramSmallIcon />
                            </div>
                        </div>
                    </div>
                    <div className='footerContainer__right'>
                        <span className='footerContainer__right__description'>Any question? Reach out to us and we’ll get back to you shortly.</span>
                        <TextField InputProps={{ disableUnderline: true, className: 'customInput' }} style={{ backgroundColor: 'transparent' }} id="filled-basic" className='TextField' label="Your name" variant="filled" />
                        <TextField InputProps={{ disableUnderline: true }} style={{ backgroundColor: 'transparent' }} id="filled-basic" className='TextField' label="Your email address" variant="filled" />
                        <TextField InputProps={{ disableUnderline: true }} style={{ backgroundColor: 'transparent' }} id="filled-basic" className='TextField' label="Your email address" rows={6} variant='filled' maxRows={6} multiline/>
                        <button className='inTouchBtn'>Get in touch</button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Landing