import React from "react";
import { ReactFC } from '../scripts/types'

const PlusIcon: ReactFC = ({ className = '' }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8V24" stroke="url(#paint0_linear_0_438)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 16H24" stroke="url(#paint1_linear_0_438)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_0_438" x1="16.0791" y1="3.05455" x2="14.7988" y2="3.06297" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F6F2FF"/>
                    <stop offset="1" stop-color="#D4BBFF"/>
                </linearGradient>
                <linearGradient id="paint1_linear_0_438" x1="6.73469" y1="15.6909" x2="12.0765" y2="24.6848" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F6F2FF"/>
                    <stop offset="1" stop-color="#D4BBFF"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default PlusIcon