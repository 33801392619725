import React from "react"
import { ReactFC } from '../scripts/types'

const BoxIcon: ReactFC<{}> = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="80" rx="16" fill="url(#paint0_linear_1_252)"/>
            <path d="M34 60H46C56 60 60 56 60 46V34C60 24 56 20 46 20H34C24 20 20 24 20 34V46C20 56 24 60 34 60Z" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.7022 37.8202C44.5022 39.2402 43.6622 40.4402 42.5022 41.1602V45.1202C42.5022 46.5002 41.3822 47.6202 40.0022 47.6202C38.6222 47.6202 37.5022 46.5002 37.5022 45.1202V41.1602C36.3422 40.4402 35.5022 39.2402 35.3022 37.8202C35.2622 37.6002 35.2422 37.3602 35.2422 37.1202C35.2422 34.0802 38.1222 31.7202 41.2822 32.5602C42.8822 32.9802 44.1822 34.2802 44.6022 35.8802C44.7822 36.5402 44.8022 37.2002 44.7022 37.8202Z" stroke="#1C0F30" stroke-width="3" stroke-miterlimit="10"/>
            <path d="M60.0031 37.8203H44.7031" stroke="#1C0F30" stroke-width="3" stroke-miterlimit="10"/>
            <path d="M35.3 37.8201H20" stroke="#1C0F30" stroke-width="3" stroke-miterlimit="10"/>
            <defs>
            <linearGradient id="paint0_linear_1_252" x1="-6.32653" y1="-15.2727" x2="95.5719" y2="-6.05494" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F6F2FF"/>
            <stop offset="1" stop-color="#D4BBFF"/>
            </linearGradient>
            </defs>
        </svg>
    )
}

export default BoxIcon