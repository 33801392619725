import React from "react"
import { ReactFC } from '../scripts/types'

const WalletIcon: ReactFC<{}> = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="80" height="80" rx="16" fill="url(#paint0_linear_1_257)"/>
        <path d="M34 54C34 55.5 33.58 56.92 32.84 58.12C31.46 60.44 28.92 62 26 62C23.08 62 20.54 60.44 19.16 58.12C18.42 56.92 18 55.5 18 54C18 49.58 21.58 46 26 46C30.42 46 34 49.58 34 54Z" stroke="#1C0F30" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.8828 53.999L24.8628 55.979L29.1228 52.0391" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.5028 30.0999C51.0228 30.0199 50.5228 30 50.0028 30H30.0028C29.4428 30 28.9028 30.04 28.3828 30.12C28.6628 29.56 29.0628 29.04 29.5428 28.56L36.0428 22.04C38.7828 19.32 43.2228 19.32 45.9628 22.04L49.4628 25.5799C50.7428 26.8399 51.4228 28.4399 51.5028 30.0999Z" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M60 40V50C60 56 56 60 50 60H31.26C31.88 59.48 32.42 58.84 32.84 58.12C33.58 56.92 34 55.5 34 54C34 49.58 30.42 46 26 46C23.6 46 21.46 47.06 20 48.72V40C20 34.56 23.28 30.76 28.38 30.12C28.9 30.04 29.44 30 30 30H50C50.52 30 51.02 30.02 51.5 30.1C56.66 30.7 60 34.52 60 40Z" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M60 41H54C51.8 41 50 42.8 50 45C50 47.2 51.8 49 54 49H60" stroke="#1C0F30" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_1_257" x1="-6.32653" y1="-15.2727" x2="95.5719" y2="-6.05494" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F6F2FF"/>
        <stop offset="1" stop-color="#D4BBFF"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

export default WalletIcon