import React from "react";
import { ReactFC } from '../scripts/types'

const PhoneIcon: ReactFC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M5.88235 4H9.64706L11.5294 8.70588L9.17647 10.1176C10.1844 12.1614 11.8386 13.8156 13.8824 14.8235L15.2941 12.4706L20 14.3529V18.1176C20 18.6169 19.8017 19.0957 19.4487 19.4487C19.0957 19.8017 18.6169 20 18.1176 20C14.4464 19.7769 10.9837 18.2179 8.3829 15.6171C5.78212 13.0163 4.22311 9.55363 4 5.88235C4 5.38312 4.19832 4.90434 4.55133 4.55133C4.90434 4.19832 5.38312 4 5.88235 4Z" fill="white"/>
        </svg>

    )
}

export default PhoneIcon