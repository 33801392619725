import React from "react"
import { ReactFC } from '../scripts/types'

const TwitterIcon: ReactFC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.91076 21.6875C16.213 21.6875 20.7546 14.8084 20.7546 8.85323C20.7546 8.66073 20.7546 8.46548 20.7464 8.27298C21.6306 7.6329 22.3937 6.84044 23 5.93273C22.1741 6.2969 21.2988 6.53691 20.4026 6.64498C21.3466 6.08073 22.0536 5.19293 22.3923 4.1466C21.5054 4.67202 20.5347 5.04087 19.5226 5.23698C18.8423 4.51238 17.9419 4.03236 16.9611 3.87127C15.9803 3.71017 14.9738 3.87699 14.0974 4.34589C13.221 4.81479 12.5236 5.55959 12.1134 6.46494C11.7031 7.37028 11.6029 8.38564 11.8281 9.35373C10.0334 9.26374 8.27763 8.7975 6.67469 7.98525C5.07175 7.173 3.65744 6.03289 2.5235 4.63885C1.94784 5.63309 1.77216 6.80916 2.03214 7.92822C2.29211 9.04729 2.96825 10.0255 3.92325 10.6641C3.20762 10.6398 2.50772 10.4476 1.88 10.1031V10.165C1.88123 11.2065 2.24205 12.2157 2.90145 13.0219C3.56086 13.8281 4.47841 14.3819 5.499 14.5897C5.11162 14.6965 4.71144 14.7497 4.30963 14.7479C4.02636 14.7487 3.74365 14.7225 3.46538 14.6695C3.75383 15.5661 4.3155 16.35 5.07172 16.9114C5.82793 17.4728 6.74082 17.7837 7.68251 17.8004C6.08277 19.0568 4.10668 19.7383 2.0725 19.735C1.71407 19.7365 1.35588 19.7158 1 19.6731C3.06458 20.9894 5.46229 21.6882 7.91076 21.6875Z" fill="#F6F2FF"/>
        </svg>
    )
}

export default TwitterIcon