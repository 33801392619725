import React from "react";
import { ReactFC } from '../scripts/types'

const GooglePlayIcon: ReactFC = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_187_105)">
                <path d="M23.8517 21.3906C27.9092 19.3091 30.9851 17.723 31.2502 17.5996C32.0984 17.1783 32.9742 16.0629 31.2502 15.1966C30.6936 14.9241 27.6967 13.3874 23.8517 11.4057L18.5215 16.4352L23.8517 21.3905V21.3906Z" fill="#FFD900"/>
                <path d="M18.5212 16.4349L1.54907 32.4166C1.94745 32.4659 2.3972 32.3672 2.92745 32.0949C4.0407 31.5255 15.8422 25.504 23.8514 21.3916L18.5212 16.4349Z" fill="#F43249"/>
                <path d="M18.5212 16.435L23.8515 11.4302C23.8515 11.4302 4.12121 1.36997 2.92746 0.776098C2.47771 0.527222 1.97371 0.453295 1.52271 0.527222L18.5212 16.435Z" fill="#00EE76"/>
                <path d="M18.5214 16.4371L1.52287 0.529366C0.833 0.678387 0.25 1.24785 0.25 2.41246V30.5357C0.25 31.6016 0.701125 32.3694 1.54925 32.4434L18.5214 16.4371Z" fill="#00D3FF"/>
            </g>
            <defs>
                <clipPath id="clip0_187_105">
                    <rect width="32" height="32" fill="white" transform="translate(0.25 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default GooglePlayIcon