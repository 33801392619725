import React from "react";
import { ReactFC } from '../scripts/types'

const AppStoreIcon: ReactFC<{}> = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_187_223)">
                <path d="M7.758 0.5H25.742C29.6125 0.5 32.75 3.6375 32.75 7.508V25.492C32.75 29.3625 29.6125 32.5 25.742 32.5H7.758C3.8875 32.5 0.75 29.3625 0.75 25.492V7.508C0.75 3.6375 3.8875 0.5 7.758 0.5Z" fill="url(#paint0_linear_187_223)"/>
                <path d="M11.0053 23.7263L11.0083 23.7273L9.91418 25.6223C9.51481 26.3141 8.63031 26.5511 7.93856 26.1517C7.24693 25.7523 7.00981 24.8678 7.40918 24.1761L8.21518 22.7801L8.29256 22.6461C8.43068 22.4476 8.77156 22.1048 9.45343 22.1693C9.45343 22.1693 11.0581 22.3434 11.1742 23.1774C11.1742 23.1774 11.1899 23.4518 11.0053 23.7263ZM26.5232 18.8863H23.1114C22.8791 18.8707 22.7777 18.7877 22.7377 18.7394L22.7352 18.7351L19.0831 12.4093L19.0783 12.4124L18.8593 12.0984C18.5003 11.5494 17.9303 12.9534 17.9303 12.9534C17.2497 14.5179 18.0269 16.2966 18.2978 16.8342L23.3706 25.6204C23.7698 26.3121 24.6543 26.5492 25.3462 26.1497C26.0378 25.7503 26.2749 24.8658 25.8754 24.1741L24.6071 21.9771C24.5824 21.9238 24.5397 21.7793 24.7998 21.7787H26.5232C27.3219 21.7787 27.9694 21.1312 27.9694 20.3324C27.9694 19.5337 27.3219 18.8862 26.5232 18.8862V18.8863ZM19.8964 20.8523C19.8964 20.8523 20.0786 21.7787 19.3739 21.7787H6.76156C5.96281 21.7787 5.31531 21.1312 5.31531 20.3324C5.31531 19.5337 5.96281 18.8862 6.76156 18.8862H10.0041C10.5276 18.8559 10.6516 18.5537 10.6516 18.5537L10.6546 18.5552L14.8871 11.2242L14.8858 11.2239C14.9629 11.0823 14.8987 10.9484 14.8876 10.9273L13.4897 8.50619C13.0903 7.81457 13.3273 6.92994 14.0191 6.53069C14.7108 6.13132 15.5953 6.36819 15.9947 7.05994L16.6429 8.18282L17.2901 7.06194C17.6894 6.37032 18.5739 6.13319 19.2657 6.53269C19.9574 6.93207 20.1944 7.81644 19.7951 8.50819L13.9053 18.7094C13.8796 18.7716 13.8717 18.8691 14.0633 18.8862H17.5838L17.5846 18.9206C17.5846 18.9206 19.6193 18.9522 19.8964 20.8523Z" fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_187_223" x1="16.75" y1="0.5" x2="16.75" y2="32.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#17C9FB"/>
                    <stop offset="1" stop-color="#1A74E8"/>
                </linearGradient>
                <clipPath id="clip0_187_223">
                    <rect width="32" height="32" fill="white" transform="translate(0.75 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default AppStoreIcon