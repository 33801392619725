import React from "react"
import { ReactFC } from '../../scripts/types'
import './gradient-button.css'
import '../../styles/variables.css'

const Button: ReactFC<{
    type: 'SOLID' | 'OUTLINE',
    label: string
}> = ({ className = '', type = 'SOLID', label }) => {
    return (
        <button className={`${className} ${type === 'SOLID' ? 'gradientButton' : 'outline'}  radius-12`}>
            {label}
        </button>
    )
}

export default Button