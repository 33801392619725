import React from "react";
import { ReactFC } from '../scripts/types'

const ArrowNextIcon: ReactFC = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 23.9996C4 35.0454 12.9549 43.9996 24 43.9996C35.0451 43.9996 44 35.0454 44 23.9996C44 12.9552 35.0444 3.99963 24 3.99963C12.9556 3.99963 4 12.9552 4 23.9996ZM7 23.9996C7 14.6121 14.6124 6.99963 24 6.99963C33.3876 6.99963 41 14.6121 41 23.9996C41 33.3885 33.3883 40.9996 24 40.9996C14.6117 40.9996 7 33.3885 7 23.9996ZM19.9114 30.047C19.4745 30.6333 19.5211 31.4667 20.0525 32.0004C20.637 32.5875 21.5867 32.5895 22.1738 32.005L29.1458 25.063L29.2918 24.8945C29.7297 24.3064 29.6811 23.4701 29.1458 22.9371L22.1738 15.9951L22.0052 15.8502C21.4171 15.4158 20.5838 15.466 20.0525 15.9997L19.9076 16.1682C19.4731 16.7564 19.5233 17.5896 20.057 18.121L25.96 24.002L20.057 29.8791L19.9114 30.047Z" fill="white"/>
        </svg>
    )
}

export default ArrowNextIcon